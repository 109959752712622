global.jQuery = require("jquery");
var $ = global.jQuery,
    History = require('./libs/jquery.history.js'),
    velocity = require('velocity-animate'),
    imagesLoaded = require('imagesLoaded'),
    slick = require('slick-carousel'),
    Packery = require('packery'),
    Isotope = require('isotope-layout'),
    featherlight = require('./libs/featherlight.min'),
    matchHeight = require('jquery-match-height'),
    LazyLoad = require('vanilla-lazyload');

var History = window.History,
    document = window.document,
    body = $(document.body),
    $window = $(window),
    mainName = '#main',
    main = $(mainName),
    slideshow = null,
    grid = null,
    equal = null,
    scrollTo = null,
    scrollOptions = {
        duration: 800,
        easing: 'swing'
    };

// HTML Helper
var documentHtml = function (html) {
    // Prepare
    var result = String(html)
        .replace(/<\!DOCTYPE[^>]*>/i, '')
        .replace(/<(html|head|body|title|meta|script)([\s\>])/gi, '<div class="document-$1"$2')
        .replace(/<\/(html|head|body|title|meta|script)\>/gi, '</div>');

    // Return
    return $.trim(result);
};

// Wait for Document
$(function () {

    'use strict';

    // Prepare Variables
    var
        /* Application Specific Variables */
        $menu = $('#nav').filter(':first'),
        activeClass = 'active',
        activeSelector = '.active',
        menuChildrenSelector = '> li,> ul > li',
        completedEventName = 'statechangecomplete',
        triggerEventName = 'statechangetrigger',
        /* Application Generic Variables */
        rootUrl = History.getRootUrl();


    if ($('html').hasClass('no-history') || (screen.width < 768)) {
        return false;
    }

    // Ensure Content
    if (main.length === 0) {
        main = body;
    }

    // Internal Helper
    $.expr[':'].internal = function (obj, index, meta, stack) {
        // Prepare
        var
            $this = $(obj),
            url = $this.attr('href') || '',
            isInternalLink;

        // Check link
        isInternalLink = url.substring(0, rootUrl.length) === rootUrl || url.indexOf(':') === -1;

        // Ignore or Keep
        return isInternalLink;
    };


    // Ajaxify Helper
    $.fn.ajaxify = function () {
        // Prepare
        var $this = $(this);

        // Ajaxify
        $this.find('a:internal:not(.no-ajaxy)').not('.ajax-ignore a').click(function (event) {
            // Prepare
            var
                $this = $(this),
                url = $this.attr('href'),
                title = $this.attr('title') || null;

            $window.trigger(triggerEventName);


            // Continue as normal for cmd clicks etc
            if (event.which == 2 || event.metaKey) {
                return true;
            }

            // Ajaxify this link
            History.pushState(null, title, url);
            event.preventDefault();
            return false;
        });

        // Chain
        return $this;
    };

    // Ajaxify our Internal Links
    body.ajaxify();

    // Hook into State Changes
    $window.bind('statechange', function () {
        // Prepare Variables
        var
            State = History.getState(),
            url = State.url,
            relativeUrl = url.replace(rootUrl, '');

        // Set Loading
        // we do all our fading in and out with css
        body.addClass('loading');

        // Start Fade Out
        // Animating to opacity to 0 still keeps the element's height intact
        // Which prevents that annoying pop bang issue when loading in new content
        main.velocity({opacity: 0}, 600, function () {
            body.removeClass();
            // Ajax Request the Traditional Page
            $.ajax({
                url: url,
                success: function (data, textStatus, jqXHR) {
                    // Prepare
                    var
                        $data = $(documentHtml(data)),
                        $dataBody = $data.find('.document-body:first'),
                        $dataContent = $dataBody.find(mainName).filter(':first'),
                        $menuChildren, contentHtml, $scripts;

                    // Fetch the scripts
                    $scripts = $dataContent.find('.document-script');
                    if ($scripts.length) {
                        $scripts.detach();
                    }

                    // Fetch the content
                    contentHtml = $dataContent.html() || $data.html();
                    if (!contentHtml) {
                        document.location.href = url;
                        return false;
                    }

                    // Update the menu
                    $menuChildren = $menu.find(menuChildrenSelector);
                    $menuChildren.filter(activeSelector).removeClass(activeClass);
                    $menuChildren = $menuChildren.has('a[href^="' + relativeUrl + '"],a[href^="/' + relativeUrl + '"],a[href^="' + url + '"]');
                    if ($menuChildren.length === 1) {
                        $menuChildren.addClass(activeClass);
                    }

                    // Update the content
                    main.stop(true, true);
                    main.html(contentHtml).ajaxify();


                    // Update the title
                    document.title = $data.find('.document-title:first').text();
                    try {
                        document.getElementsByTagName('title')[0].innerHTML = document.title.replace('<', '&lt;').replace('>', '&gt;').replace(' & ', ' &amp; ');
                    } catch (Exception) {
                    }

                    // Add the scripts
                    $scripts.each(function () {
                        var $script = $(this), scriptText = $script.text(),
                            scriptNode = document.createElement('script');
                        if ($script.attr('src')) {
                            if (!$script[0].async) {
                                scriptNode.async = false;
                            }
                            scriptNode.src = $script.attr('src');
                        }
                        scriptNode.appendChild(document.createTextNode(scriptText));
                        main.appendChild(scriptNode);
                    });

                    // Complete the change


                    body.velocity("scroll", scrollOptions);

                    /* http://balupton.com/projects/jquery-scrollto */
                    body.removeClass('loading');
                    $window.trigger(completedEventName);

                    // Inform Google Analytics of the change
                    if (typeof window._gaq !== 'undefined') {
                        window._gaq.push(['_trackPageview', relativeUrl]);
                    }

                    // Inform ReInvigorate of a state change
                    if (typeof window.reinvigorate !== 'undefined' && typeof window.reinvigorate.ajax_track !== 'undefined') {
                        reinvigorate.ajax_track(url);
                        // ^ we use the full url here as that is what reinvigorate supports
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    document.location.href = url;
                    return false;
                }
            }); // end ajax

        });


    }); // end onStateChange
});


$(function ($) {

    'use strict';

    function siteLoaded(callback) {
        // images have loaded
        body.imagesLoaded(function () {
            /*
             if(!body.hasClass('loaded')) {
             loader.fadeOut(300, function()
             body.addClass('loaded');
             callback();
             });
             } else {
             callback();
             }
             */
            callback();
        });
    }

    $('#menu-toggle').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('active');
        $('#nav').toggleClass('active');
    });


    function gridSystem() {

        grid = $('#grid');

        if (grid.length > 0) {
            grid = new Isotope('#grid', {
                layoutMode: 'fitRows',
                itemSelector: '.item'
            });


            $('.filter a').on('click', function (e) {
                e.preventDefault();

                if ($(this).hasClass('active')) {
                    return false;
                }

                $('#grid').show();
                $('#archive').hide();

                $('.filter a').removeClass('active');
                $(this).addClass('active');

                var filterValue = $(this).attr('data-filter');
                $('.project').removeClass('hidden');
                grid.arrange({filter: filterValue});
                grid.layout();

                myLazyLoad.update();
                setupMatchHeight();

            });

            $('#archive-toggle').on('click', function (e) {
                e.preventDefault();
                $('#grid').hide();
                $('#archive').show();
            });

            $('.preview-toggle').mouseenter(function () {
                var img = $(this).data('archive-image');
                $('.preview-image').show();
                $('.preview-image').attr('src', img);
            })
            .mouseleave(function () {
                $('.preview-image').hide();
            });

        }
    }


    function innerGridSystem() {

        grid = $('#innerGrid');

        if (grid.length > 0) {
            grid = new Isotope('#innerGrid', {
                layoutMode: 'packery',
                itemSelector: '.item'
            });

        }
    }


    function setupSlideshow() {
        slideshow = $('.slideshow');
        if (slideshow.length > 0) {
            slideshow.slick({
                "arrows": false,
                "dots": false,
                "infinite": true,
                "slidesToScroll": 1,
                "slidesToShow": 1,
                "speed": 600,
                "autoplay": true,
                "fade": true,
                "cssEase": "linear"
            });
        }
    }

    function setupSlideGallery() {
        slideshow = $('.slideshow-gallery');
        if (slideshow.length > 0) {
            slideshow.slick({
                "arrows": true,
                "dots": false,
                "infinite": true,
                "slidesToScroll": 1,
                "slidesToShow": 1,
                "speed": 400,
                "autoplay": false,
                "fade": true,
                "cssEase": "linear"
            });

            //slideshow.find('.caption').hide();

            //slideshow.find('.caption').first().fadeIn();

            slideshow.on('beforeChange', function (event, slick, currentSlide) {
                //    slideshow.find('.caption').fadeOut();
            });


            slideshow.on('afterChange', function (event, slick, currentSlide) {
                //    console.log(currentSlide);
                //    slideshow.find('.item').eq(currentSlide).fadeIn();
            });
        }
    }

    function scrollTo() {

        scrollTo = $('#scroll-to');
        if (scrollTo.length > 0) {
            scrollTo.on('click', function (e) {
                e.preventDefault();
                var target = $(this).attr('href');
                $(target).velocity("scroll", scrollOptions);
            })
        }
    }


    function setupMatchHeight() {

        equal = $('.equal');
        if (equal.length > 0) {
            equal.matchHeight();
        }
    }

    function readMore() {
        $(".more").click(function (e) {
            e.preventDefault();
            $('#readmore').addClass('active');
            $('.read-more').fadeOut();
            return false;
        });
    }

    function shareMe() {
        $('.share-me').on('click', function (e) {
            e.preventDefault();

            var me = $(this).attr('href');
            window.open(
                me,
                'share-dialog',
                'width=626,height=436');
            return false;
        });
    }

    function googleRecapture() {
        if ($('#recapture').length > 0) {
            grecaptcha.render('recapture', {
                sitekey: '6Le3E68UAAAAAKM4jDNJrc1W83SFQo889wgF0zec',
            });
        }
    }

    var myLazyLoad = new LazyLoad({});

    $window.on('statechangecomplete', function () {
        siteLoaded(function () {
            main.velocity({opacity: 1}, 600, function () {
                setupSlideshow();
                setupSlideGallery();
                gridSystem();
                innerGridSystem
                setupMatchHeight();
                myLazyLoad.update();
                readMore();
                shareMe();
                googleRecapture();
                scrollTo();
            });
        });
    });

    $window.on('statechangetrigger', function (e, eventInfo) {
    });

    siteLoaded(function () {
        main.velocity({opacity: 1}, 600, function () {
            setupSlideshow();
            setupSlideGallery();
            gridSystem();
            innerGridSystem
            setupMatchHeight();
            readMore();
            shareMe();
            googleRecapture();
            scrollTo();
        });
    });

});